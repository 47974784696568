import { JobAction } from "../types";
import { JobItem, Job } from "../../../server/model/job";
import { MessageInfo } from "../types/info";
import { Option } from "lu-component";

export type State = {
	jobs: JobItem[];
	search: Partial<Job>;
	accounts: Option[];
	loading: boolean;
	info: MessageInfo;
};
export const initState: State = {
	jobs: [],
	search: {},
	accounts: [],
	loading: true,
	info: {
		message: "",
		isSuccess: true
	}
};

export const reducer = (state: State, action: JobAction): State => {
	switch (action.type) {
		case "loadJobs":
			return { ...state, ...action.payload, loading: false };
		case "setJobs":
			return { ...state, jobs: action.payload };
		case "addJob":
			return {
				...state,
				jobs: state.jobs.concat(action.payload),
				info: { message: "jobの登録が完了しました。", isSuccess: true }
			};
		case "changeJob":
			return {
				...state,
				jobs: state.jobs.map(job =>
					job._id === action.payload._id ? action.payload : job
				),
				info: { message: "jobの更新が完了しました。", isSuccess: true }
			};
		case "deleteJob":
			return {
				...state,
				jobs: state.jobs.filter(job => job._id !== action.payload),
				info: { message: "jobの削除が完了しました。", isSuccess: true }
			};
		case "changeMessageInfo":
			return {
				...state,
				info: action.payload
			};
		case "changeLoading":
			return {
				...state,
				loading: action.payload
			};
	}
};
