import * as React from "react";
import { ListTable, Column, EditForm, EventItem } from "lu-component";
import {
	AccountWithoutPassowrd,
	Account
} from "../../../../../server/model/account";
import { Row, Col } from "react-bootstrap";
import { accountLabels } from "../../../../../server/label/account";
import { ErrorObject } from "../../../../../server/validation/validation-base";

export type AccountPageProps = {
	accounts: AccountWithoutPassowrd[];
	onCloseEvent: () => void;
	errors?: ErrorObject;
	onUpsert: (account: Account, cb?: () => void) => void;
};
const columns: Column<Account>[][] = [
	[
		{
			name: "name",
			type: "text",
			label: accountLabels.name,
			col: 7
		}
	],
	[
		{
			name: "mail",
			type: "text",
			label: accountLabels.mail,
			col: 10
		}
	],
	[
		{
			name: "password",
			type: "text",
			label: accountLabels.password,
			col: 10,
			hide: true
		}
	],
	[
		{
			name: "isActive",
			type: "checkbox",
			label: accountLabels.isActive,
			col: 3
		},
		{
			name: "isAdmin",
			type: "checkbox",
			label: accountLabels.isAdmin,
			col: 3
		}
	]
];
export const AccountPage = React.memo((props: AccountPageProps) => {
	const { accounts, onUpsert, errors, onCloseEvent } = props;
	const events: EventItem[] = React.useMemo(
		() => [
			{
				text: "編集",
				variant: "outline-success",
				action: onUpsert,
				type: "edit",
				onCloseEvent: onCloseEvent
			}
		],
		[onUpsert]
	);
	return (
		<>
			<Row>
				<Col md={12}>
					<div className={"h3"}>■ アカウント一覧</div>
				</Col>
				<Col md={{ offset: 10, span: 2 }}>
					<div className={"m-3"}>
						<EditForm
							columns={columns}
							onSubmit={onUpsert}
							errors={errors}
							data={{ isActive: true, isAdmin: false }}
							onCloseEvent={onCloseEvent}
						/>
					</div>
				</Col>
			</Row>
			<ListTable
				data={accounts}
				events={events}
				columns={columns}
				errors={errors}
			/>
		</>
	);
});
